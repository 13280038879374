import React, { useState, useEffect } from 'react';
import './logoutpopup.css';
import {  ArrowForward } from '@mui/icons-material';
import { ClockIcon } from '@mui/x-date-pickers';

interface propsHead {
    openClose: any;
    hadleLogout: (user: any) => void;
}

const Logoutpopup: React.FC<propsHead> = ({ openClose, hadleLogout }) => {


    const closePopup = () => {
        openClose(false);
    }

    // const logout = () => {
    //     hadleLogout;
    // }

    const [seconds, setSeconds] = useState<number>(120);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        if(seconds === 0)
        {
            hadleLogout(null);
        }

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [seconds]);



    return (
        <>
            <div className='logout_main'>
                <div className='logout_header'>
                    <span><ClockIcon /> &nbsp;Session Timeout</span>
                </div>
                <div className='logout_content'>
                    <div className='logout_sub'>
                        <span style={{ marginTop: 10 }}>
                            your online session will expire in </span><span style={{ marginTop: 12, fontSize: '23px', fontWeight: '700' }}>
                            {seconds === 0 ? (
                                <>Time's up!</>
                            ) : (
                                <>{seconds}/120 seconds</>
                            )}
                        </span><span style={{ marginTop: 10 }}> Please click "Continue" to keep working </span>
                        <span >
                            or click "Log off" to end your session now
                        </span>

                    </div>
                    <div className='logout_btns'>
                        &nbsp;
                        <button className='logbtn1' onClick={hadleLogout}><span><ClockIcon /></span> Logoff</button>&nbsp;
                        <button className='logbtn1 btn2' onClick={closePopup} >Continue<span><ArrowForward /></span></button>
                    </div>

                </div>
            </div>


        </>
    );
};

export default Logoutpopup;
