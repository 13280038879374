import { commanServicer } from "../Commen_Services/common.services";

export const EmployeeMasterService = {
    SaveorUpdateEmployee, 
    getEmployeeById,
}


async function SaveorUpdateEmployee(EmployeeData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(EmployeeData), true, 'EmployeeMaster/saveorupdatEmployee','POST');
}


async function getEmployeeById(id : any) {
    
    return await commanServicer.fetchRequest('',true,'EmployeeMaster/getEmployee?employeeId='+id,'GET');
}
