import { authHeader } from '../Auth/auth-header';
import AWS from 'aws-sdk';

export const commanServicer = {
    fetchRequest,
    UploadImage

}



async function fetchRequest(Reqbody: any, AuthStatus: Boolean, endPoint: string, method: string = 'POST') {
    let requestOption: any = {};
    
    if (method === 'GET') {
        requestOption = {
            method: method,
            headers: AuthStatus === true ? { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': authHeader() } : { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        }
    }
    else {
        requestOption = {
            method: method,
            headers: AuthStatus === true ? { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': authHeader() } : { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: Reqbody
        }
    }

    let APIUrl = `${process.env.REACT_APP_API_URL}${endPoint}`;

    let response = await fetch(APIUrl, requestOption);

    if (response.status === 401) {
        return { statusCode: 401 };
    }
    else if (response.status === 400) {
        return { statusCode: 400 };
    }
    else {
        const result = await response.json();
        result.statusCode = 200;
       // console.log('result', result);
        return result;
    }
}




async function UploadImage(Path: string, DocumentName: string, image: any) {
    

    const spacesEndpoint = String(process.env.REACT_APP_REGION_URL);
    const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_SECREATE_ACCESS_KEY;
    const MainPath = process.env.REACT_APP_MAINPATH;
    //const bucketName = 'ethics-static';
    const bucketName = String(process.env.REACT_APP_BUCKET_NAME);


    
    var s3 = new AWS.S3({
        endpoint: spacesEndpoint,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    });


   
    const photoKey = MainPath + Path + DocumentName;

    var params = {
        ACL: 'public-read',
        Bucket: bucketName,
        Key: photoKey,
        Body: image
    };

    return await s3.upload(params).promise()
        .then((res: any) => {
            console.log(`Upload succeeded - `, res);
            return res.Location;
        })
        .catch((err: any) => {
            console.log("Upload failed:", err);
            return false;
        });

}

// function checkResponse(result: any) {
//     if (result.statusCode == 200) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }
// function CheckRights(location: any, RighsCollection: any) {
//     let Status: boolean = true;
//     for (let indexVal = 0; indexVal < RighsCollection.length; indexVal++) {
//         switch (RighsCollection[indexVal]) {
//             case UserRightConstant.ADD:
//                 Status = location.state?.Add;
//                 break;
//             case UserRightConstant.EDIT:
//                 Status = location.state?.Edit;
//                 break;
//             case UserRightConstant.VIEW:
//                 Status = location.state?.View;
//                 break;
//         }
//         if (Status == false) {
//             break;
//         }
//     }
//     return Status;
//     if (Status == false) {
//         //navigate('/Dashboard');
//     }
// }

// function SessionCollection() {
//     debugger;
//     let loggedInUser: any = localStorage.getItem("user") ? localStorage.getItem("user") : null;
   
//     let SessionObj = {
       
//         defaultPageSize: !(loggedInUser == undefined ||  loggedInUser==null) ? JSON.parse(loggedInUser).userDetail.defaultPageSize : 100,
//         fullName: !(loggedInUser == undefined ||  loggedInUser==null) ? JSON.parse(loggedInUser).userDetail.fullName : "",
//         userRoleId: !(loggedInUser == undefined ||  loggedInUser==null) ? JSON.parse(loggedInUser).userDetail.userRoleId : "",
//         salerList: !(loggedInUser == undefined ||  loggedInUser==null) ? JSON.parse(loggedInUser).userDetail.salerList : ""
//     }
//     return SessionObj;
// }




// async function ImageDelete(imagePath: string) {
//     const spacesEndpoint = new AWS.Endpoint(process.env.REACT_APP_REGION + '.digitaloceanspaces.com');
//     const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
//     const secretAccessKey = process.env.REACT_APP_SECREATE_ACCESS_KEY;
//     const MainPath = process.env.REACT_APP_MAINPATH;
//     const bucketName = 'static-ethics';
//     // aws.config.update({accessKeyId: process.env.AWS_ACCESS_KEY_ID, secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY});
//     var s3 = new AWS.S3({
//         endpoint: spacesEndpoint,
//         accessKeyId: accessKeyId,
//         secretAccessKey: secretAccessKey
//     });
//     const photoKey = MainPath + imagePath;

//     var params = {
//         Bucket: bucketName,
//         Key: photoKey
//     };
//     await s3.deleteObject(params).promise().then((data: any) => console.log('sucessfully deleted ' + imagePath, data)).catch((err: any) => console.log('err: ', err));
// }