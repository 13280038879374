import { commanServicer } from "../Commen_Services/common.services";

export const RoleRightMasterService = {

    getUserMenu,
    SaveorUpdate,
    getRoleRigthDTL,
    getRoleRigthAccessSum,

}


async function getUserMenu() {

    return await commanServicer.fetchRequest('', true, 'UserMaster/GetUserMenu', 'GET');
}


async function SaveorUpdate(RoleRights: any) {

    return await commanServicer.fetchRequest(JSON.stringify(RoleRights), true, 'RoleRightMaster/saveorupdaterolerights', 'POST');
}



async function getRoleRigthDTL(ID: any) {
  
        return await commanServicer.fetchRequest('', true, 'RoleRightMaster/getrolerights?RoleID=' + ID , 'GET');

    


}

async function getRoleRigthAccessSum(MasterID: any) {
  
    return await commanServicer.fetchRequest('', true, 'RoleRightMaster/getrolerightaccesssum?MasterID=' + MasterID , 'GET');




}


