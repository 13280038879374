import { commanServicer } from "../Commen_Services/common.services";

export const HeadquarterMasterService = {
    getHQById,
    getLinkTableData,
    saveOrUpdateHeadQuarterData, 
    getList, 
}

async function getHQById(id : any) {
    return await commanServicer.fetchRequest('',true,'HeadquarterMaster/getHeadquarterdetailbyid?id='+id,'GET');
}
async function getLinkTableData(data: any) {
  
    return await commanServicer.fetchRequest('', true, 'HeadquarterMaster/getlinkdatabyarea?id=' + data, 'GET');
}

async function saveOrUpdateHeadQuarterData(data:any) {
   
    return await commanServicer.fetchRequest(JSON.stringify(data),true,'HeadquarterMaster/saveorupdateHeadquarterdetails', 'POST');
}
async function getList(accessKey: any, id: any, hqid: any) {
    return await commanServicer.fetchRequest('', true, 'HeadquarterMaster/getcommanlist?Sp_Status=' + accessKey+'&linkData='+id+'&HqId='+hqid, 'GET');
}

