import React from "react";
import empty_state from "../assets/Images/empty_state.png"


const PageNotFound = () => {


    return (
        <>
           <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={empty_state} height="300px" alt="Empty State" />
           </div>
        </>
    )
}

export default PageNotFound