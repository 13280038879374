import { Box, Collapse, createTheme, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { userServicer } from '../../Commen_Services/user.services';
import PageNotFound from '../../../pages/PageNotFound';
import { GrDown, GrUp } from 'react-icons/gr';
import { table } from 'console';
import { RoleRightMasterService } from '../../Commen_Services/RoleRightMaster.service';
import { AccessLevel } from '../../../_enums/enum.services';
import Popup from 'reactjs-popup';
import { Add, Close, Save } from '@mui/icons-material';
import customToast from '../../Commen_Component/Toast/CustomToast';
import { useNavigate } from 'react-router-dom';



interface CheckboxProps {
    key1: any;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
}


const Checkbox: React.FC<CheckboxProps> = ({ key1, isChecked, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return (
        <div>
            <label>
                <input
                    key={key1}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                />
            </label>
        </div>
    );
};





const RoleRightMaster = () => {
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [inputs, setInputs] = useState<any>({});
    const [error, setError] = useState<any>({});
    const [selectedRoleID, setSelectedRoleID] = useState('');
    const [userMenu, setUserMenu] = useState([]);
    const [accessSum, setAccessSum] = useState<any[]>([]);
    const [enumSum, setEnumSum] = useState(0);

    const handleChangeMUIforRoleId = (event: any) => {

        const name = event.target.name;
        const value = event.target.value;
        setSelectedRoleID(value);
        setError((prevErrors: any) => ({ ...prevErrors, roleId: '' }));
        setInputs((values: any) => ({ ...values, [name]: value }))

    }

    const tableHead = [{ HeaderName: 'Name', value: 0, value2: 0 },
    { HeaderName: 'ALL', value: 2159, value2: 3944 },
    { HeaderName: 'View', value: AccessLevel.View, value2: AccessLevel.ViewNew },
    { HeaderName: 'Add', value: AccessLevel.New, value2: AccessLevel.AddNew },
    { HeaderName: 'Edit', value: AccessLevel.Edit, value2: AccessLevel.EditNew },
    { HeaderName: 'Delete', value: AccessLevel.Delete, value2: AccessLevel.Delete },
    { HeaderName: 'Filter', value: AccessLevel.Filter, value2: AccessLevel.Filter },
    { HeaderName: 'Print', value: AccessLevel.Print, value2: AccessLevel.Print },
    { HeaderName: 'Export', value: AccessLevel.Export, value2: AccessLevel.Export },
    { HeaderName: 'Send', value: AccessLevel.Send, value2: AccessLevel.Send }];

    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [parentChecked, setParentChecked] = useState(false);

    const handleParentChange = (event: any, pageID: any, Enum: any) => {
        // const overallEnum = Object.values(AccessLevel);
        const overallEnum = Enum;
        const sum = overallEnum.reduce((total: any, currentValue: any) => total = total + currentValue, 0);
        setParentChecked(event);
        if (event) {
            setAccessSum((prevAccessSum) => {

                const existingIndex = prevAccessSum.findIndex((item) => item.masterID === pageID);

                if (existingIndex !== -1) {

                    const updatedAccessSum = [...prevAccessSum];
                    updatedAccessSum[existingIndex] = {
                        ...updatedAccessSum[existingIndex],
                        accessLevel: sum
                    };
                    return updatedAccessSum;
                } else {

                    return [
                        ...prevAccessSum,
                        { masterID: pageID, accessLevel: sum }
                    ];
                }
            });


        }
        else {


            setAccessSum((prevAccessSum) => {

                const existingIndex = prevAccessSum.findIndex((item) => item.masterID === pageID);

                if (existingIndex !== -1) {

                    const updatedAccessSum = [...prevAccessSum];
                    updatedAccessSum[existingIndex] = {
                        ...updatedAccessSum[existingIndex],
                        accessLevel: 0
                    };
                    return updatedAccessSum;
                } else {

                    return [
                        ...prevAccessSum,
                        { masterID: pageID, accessLevel: 0 }
                    ];
                }
            });

        }
    };

    const handleChildChange = (event: any, pageID: any, value: any) => {

        if (event) {
            setEnumSum(enumSum + value);

            setAccessSum((prevAccessSum) => {
                const existingIndex = prevAccessSum.findIndex((item) => item.masterID === pageID);

                if (existingIndex !== -1) {

                    const updatedAccessSum = [...prevAccessSum];
                    updatedAccessSum[existingIndex] = {
                        ...updatedAccessSum[existingIndex],
                        accessLevel: updatedAccessSum[existingIndex].accessLevel + value
                    };
                    return updatedAccessSum;
                } else {

                    return [
                        ...prevAccessSum,
                        { masterID: pageID, accessLevel: value }
                    ];
                }
            });
        }
        else {
            setEnumSum(enumSum - value);

            setAccessSum((prevAccessSum) => {
                const existingIndex = prevAccessSum.findIndex((item) => item.masterID === pageID);

                if (existingIndex !== -1) {

                    const updatedAccessSum = [...prevAccessSum];
                    updatedAccessSum[existingIndex] = {
                        ...updatedAccessSum[existingIndex],
                        accessLevel: updatedAccessSum[existingIndex].accessLevel - value
                    };
                    return updatedAccessSum;
                } else {

                    return [
                        ...prevAccessSum,
                        { masterID: pageID, accessLevel: value }
                    ];
                }
            });


        }


    };

    useEffect(() => {

        setInputs((values: any) => ({ ...values, accessLevelDTL: accessSum }));

    }, [accessSum]);

    useEffect(() => {
        const fetchRolesDTL = async () => {
            try {
                let roleDetail = await RoleRightMasterService.getRoleRigthDTL(selectedRoleID);
                setAccessSum(roleDetail.data);
            }
            catch {
                console.log("error While fetchiung Data");

            }
        }

        fetchRolesDTL()

    }, [selectedRoleID])





    useEffect(() => {
        const fetchRoles = async () => {

            try {
                const data = await userServicer.getDropDownlist("GetRoles", null);
                const h = data.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));
                setOptions(transformedData);
            }
            catch {
                console.log("error While fetchiung Dropdown");

            }

        }
        const fetchUserMenu = async () => {

            try {
                const data = await RoleRightMasterService.getUserMenu();
                const usermenuData = data.data;
                setUserMenu(data);
            }
            catch {
                console.log("error While fetchiung Dropdown");

            }

        }


        fetchRoles();
        fetchUserMenu();

    }, []);


    const tableTheme = createTheme({
        components: {
            MuiTableHead: {
                styleOverrides: {
                    root: {

                        lineHeight: '5px',
                    }
                }
            }
        }
    })

    const toggleSubMenu = (itemId: any) => {
        setActiveSubMenu(itemId === activeSubMenu ? null : itemId);
    };

    const findSumElements = (target: any): number[] => {

        const arr = Object.values(AccessLevel);
        let result: number[] = [];
        let n = arr.length;


        let found = false;

        for (let i = 0; i < (1 << n); i++) {
            let sum = 0;
            let subset: number[] = [];

            for (let j = 0; j < n; j++) {
                if (i & (1 << j)) {
                    sum += arr[j];
                    subset.push(arr[j]);
                }
            }


            if (sum === target) {

                result = subset;
                found = true;
                break;
            }
        }
        return found ? result : [];
    };

    function Row(props: { row: any; keyValue: any }) {
        const { row, keyValue } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment key={`${keyValue}-RoWData`}>
                {
                    row.children === null ?
                        (
                            <TableRow key={`${keyValue}-childRow`}>
                                <TableCell ></TableCell>
                                <TableCell>{row.Title}</TableCell>
                                {
                                    tableHead.map((item: any, index) => {
                                        let currentAccessLevelSum = null;
                                        let IsParentChecked = false;

                                        const excludedKeys = ['View', 'New', 'Edit', 'ViewHistory'];
                                        const excludedKeys2 = ['ViewNew', 'AddNew', 'EditNew', 'ViewHistory'];

                                        const filteredAccessLevel = Object.fromEntries(
                                            Object.entries(AccessLevel).filter(([key]) => !excludedKeys.includes(key))
                                        );

                                        const filteredAccessLevel2 = Object.fromEntries(
                                            Object.entries(AccessLevel).filter(([key]) => !excludedKeys2.includes(key))
                                        );

                                        const overallEnum = row.IsRedirected ? Object.values(filteredAccessLevel) : Object.values(filteredAccessLevel2);
                                        const sum = overallEnum.reduce((total, currentValue) => total + currentValue, 0);
                                        accessSum.map((im: any) => {

                                            if (im.masterID === row.Id) {
                                                currentAccessLevelSum = im.accessLevel
                                            }
                                        });
                                        if (currentAccessLevelSum === sum) {
                                            IsParentChecked = true;
                                        }

                                        const EnumsSum = findSumElements(currentAccessLevelSum);

                                        return (item.HeaderName !== 'Name' && (item.HeaderName !== 'ALL' ? (<TableCell key={`${keyValue}-childRow-${index}`}><Checkbox
                                            key1={item.Id}
                                            isChecked={row.IsRedirected ? EnumsSum.some(itm => itm === item.value2) : EnumsSum.some(itm => itm === item.value)}
                                            onChange={(event) => {
                                                row.IsRedirected
                                                    ? handleChildChange(event, row.Id, item.value2)
                                                    : handleChildChange(event, row.Id, item.value);
                                            }}
                                        /></TableCell>) : (
                                            <TableCell key={`${keyValue}-childRow-${index}`}><Checkbox
                                                key1={item.Id}
                                                isChecked={IsParentChecked}
                                                onChange={(event) => handleParentChange(event, row.Id, overallEnum)}
                                            /></TableCell>)))
                                    })

                                }

                            </TableRow>
                        ) :
                        (<>
                            <TableRow key={`${keyValue}-parentRow`}>
                                <TableCell  >
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => toggleSubMenu(row.Title)}
                                    >
                                        {activeSubMenu === row.Title ? <GrUp /> : <GrDown />}
                                    </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row" colSpan={tableHead.length}>
                                    {row.Title}
                                </TableCell>


                            </TableRow>
                            {
                                activeSubMenu === row.Title && (


                                    row.children.map((childItem: any, index: any) => (
                                        <TableRow key={`${childItem.Id}-${'childRow'}-${index}`}>
                                            <TableCell></TableCell>
                                            <TableCell style={{ paddingTop: 3, paddingBottom: 3 }}>{childItem.Title}</TableCell>
                                            {
                                                tableHead.map((item: any) => {


                                                    let currentAccessLevelSum = null;
                                                    let IsParentChecked = false;
                                                    const excludedKeys = ['View', 'New', 'Edit', 'ViewHistory'];
                                                    const excludedKeys2 = ['ViewNew', 'AddNew', 'EditNew', 'ViewHistory'];

                                                    const filteredAccessLevel = Object.fromEntries(
                                                        Object.entries(AccessLevel).filter(([key]) => !excludedKeys.includes(key))
                                                    );

                                                    const filteredAccessLevel2 = Object.fromEntries(
                                                        Object.entries(AccessLevel).filter(([key]) => !excludedKeys2.includes(key))
                                                    );

                                                    const overallEnum = childItem.IsRedirected ? Object.values(filteredAccessLevel) : Object.values(filteredAccessLevel2);
                                                    const sum = overallEnum.reduce((total, currentValue) => total + currentValue, 0);

                                                    accessSum.forEach((im: any) => {
                                                        if (im.masterID === childItem.Id) {

                                                            currentAccessLevelSum = im.accessLevel;
                                                        }
                                                    });

                                                    if (currentAccessLevelSum === sum) {
                                                        IsParentChecked = true;
                                                    }

                                                    const EnumsSum = findSumElements(currentAccessLevelSum);


                                                    return (
                                                        item.HeaderName !== 'Name' && (
                                                            item.HeaderName !== 'ALL' ? (
                                                                <TableCell key={`${childItem.Id}-${item.HeaderName}`}>
                                                                    <Checkbox
                                                                        key1={`${childItem.Id}-'child'`}
                                                                        isChecked={childItem.IsRedirected ? EnumsSum.some(itm => itm === item.value2) : EnumsSum.some(itm => itm === item.value)}
                                                                        onChange={(event) => {
                                                                            childItem.IsRedirected
                                                                                ? handleChildChange(event, childItem.Id, item.value2)
                                                                                : handleChildChange(event, childItem.Id, item.value);
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell key={`${childItem.Id}-ALL`}>
                                                                    <Checkbox
                                                                        key1={`${childItem.Id}-'parent'`}
                                                                        isChecked={IsParentChecked}
                                                                        onChange={(event) => handleParentChange(event, childItem.Id, overallEnum)}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        )
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    )))

                            }
                        </>)}




            </React.Fragment>

        );




    }


    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        navigate('/');
    };



    const handelSubmit = (event: any) => {
        event.preventDefault();

        if (!inputs.roleId) {
            setError((prevErrors: any) => ({ ...prevErrors, roleId: "Please Select Role" }));
        }
        if (inputs.roleId !== undefined && inputs.accessLevelDTL !== undefined) {
            if (error.roleId === '') {
                try {

                    RoleRightMasterService.SaveorUpdate(inputs).then(user => {
                        if (user.status === true) {
                            customToast.success(user.message)
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }









    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;Rights</span>
                    </div>
                    <hr className="colorgraph" />
                </div>
            </div>
            <div>
                <FormControl focused={!!inputs.roleId} variant="standard" sx={{ width: 250, minWidth: 120, marginRight: 1 }}>
                    <InputLabel id="demo-simple-select-standard-label" sx={{
                        fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                    }}>Select Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        autoComplete="inside"
                        name="roleId"
                        error={!!error.roleId}
                        value={selectedRoleID}
                        onChange={handleChangeMUIforRoleId}
                        label="Search c"
                        onClose={(e) => e.preventDefault()}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                },
                            },
                        }}
                    >
                        {options.map((item: any) => (
                            <MenuItem key={item.OptionID} value={item.OptionID}>
                                {item.OptionName}
                            </MenuItem>
                        ))}
                    </Select>
                    {error.roleId ? (
                        <FormHelperText sx={{
                            color: 'red', fontSize: 9, maxWidth: '210px'
                        }}>{error.roleId}</FormHelperText>
                    ) : (
                        <FormHelperText sx={{ color: 'white', fontSize: 9 }}>Helper Text</FormHelperText>
                    )}
                </FormControl>
            </div>

            <div className='role-right-table'>

                <TableContainer sx={{ marginBottom: '50px', height: 'fit-content', maxHeight: '450px' }}>
                    <Table sx={{ minWidth: 50, overflowY: 'auto', }}
                        aria-labelledby="tableTitle"
                        stickyHeader aria-label="sticky table">
                        <ThemeProvider theme={tableTheme}>
                            <TableHead sx={{ '& th': { height: '10px' } }}>

                                <TableRow>
                                    <TableCell style={{ paddingTop: 3, paddingBottom: 3, }} />
                                    {tableHead.map((heading: any, index) => (
                                        <TableCell key={`${index}-heading`} style={{ paddingTop: 3, paddingBottom: 3, }}>{heading.HeaderName}</TableCell>
                                    ))}
                                </TableRow>

                            </TableHead>
                        </ThemeProvider>
                        <TableBody>

                            {
                                userMenu?.map((userItem: any, RowIndex) => (
                                    <React.Fragment key={`${RowIndex}-${'ROWS'}`}><Row row={userItem} keyValue={RowIndex} /></React.Fragment>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>

            <div>
                <div className="Area_btn" style={{ marginBottom: '30px' }}>
                    <button className={`btn123`} onClick={(event) => { handelSubmit(event); }} style={{ paddingRight: 12, height: '30px' }}><Save />&nbsp;{inputs.roleID > 0 ? <>Update</> : <>Save</>}</button>&nbsp;
                    <button className={`btn123`} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>

                    {showConfirmation && (
                        <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                            <div className="confirmation-modal">
                                <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                                <div className="canel_btns">
                                    <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                    <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                                </div>
                            </div>
                        </Popup>
                    )}
                </div>

            </div>


        </>
    );

};

export default RoleRightMaster;