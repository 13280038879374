export function authHeader() {
    const userString = localStorage.getItem('user');
    
    if (userString) {
        const user = JSON.parse(userString);
       
        if (user && user.access_token) {
            return "Bearer " + user.access_token;
        } else {
            return undefined; // No access token found
        }
    } else {
        return undefined; // No user found in localStorage
    }
}