import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { userServicer } from "../../Commen_Services/user.services";
import { Add, Close, Save } from "@mui/icons-material";
import { AreaMasterService } from "../../Commen_Services/AreaMaster.service";
import customToast from "../../Commen_Component/Toast/CustomToast";
import Popup from "reactjs-popup";


interface propsHead {
    columndata: any[];
    editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;
}

interface RowData {
    [key: string]: any;
}

type InputsType = {
    areaId: number;
    areaCode: string;
    areaName: string;
    city_Id: number;
    pinCodeId: number;
    isActive: boolean;
};

const initialInputs: InputsType = {
    areaId: 0,
    areaCode: '',
    areaName: '',
    city_Id: 0,
    pinCodeId: 0,
    isActive: true
};


const AddArea: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {

    //const [inputs, setInputs] = useState(initialInputs);
    const [inputs, setInputs] = useState<any>({});
    const [error, setError] = useState({
        areaName: '',
        areaCode: '',
        pinCodeId: '',
        countryId: '',
        stateId: '',
        city_Id: ''
    });

    const [disable, setDisable] = useState(true);
    const [visibleUPdate, setVisibleUpdate] = useState(false);
    const [editable, setEditable] = useState(false);
    const [isSave, setIssave] = useState(false);
    const [use, setUse] = useState(true);
    const [cancel, setCancel] = useState(true);
    const [updcancel, setUpdCancel] = useState(true);
    const [selectedCoutnry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [options, setOptions] = useState([]);
    const [optionsforState, setOptionsforState] = useState([{ OptionID: 1, OptionName: "--Select State--" }]);
    const [optionPincode, setOptionPincode] = useState([{ OptionID: 1, OptionName: "--Select Pincode--" }]);
    const [optionCity, setOptionCity] = useState([{ OptionID: 1, OptionName: "--Select City--" }]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [handleCancel, setCancel2] = useState(false);



    useEffect(() => {
        const fetcOption = async () => {
            try {
                const data = await userServicer.getDropDownlist("Country", null);
                const pincode = await userServicer.getDropDownlist("GetPincode", null);
                const h = data.data;
                const p = pincode.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));

                const transFormPincode = p.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(p[0])[0]],
                    OptionName: item[Object.keys(p[0])[1]] === undefined ? item[Object.keys(p[0])[0]] : item[Object.keys(p[0])[1]]
                }));

                // console.log(transFormPincode);

                setOptions(transformedData);
                setOptionPincode(transFormPincode);

            }
            catch {
                console.log("Error");

            }

        }

        fetcOption();


    }, [])

    useEffect(() => {

        const fetchState = async () => {

            let statList1 = await userServicer.getDropDownlist("StateMaster", Number(selectedCoutnry))

            let statList = statList1.data;
            const transformedData1 = statList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(statList[0])[0]],
                OptionName: item[Object.keys(statList[0])[1]] === undefined ? item[Object.keys(statList[0])[2]] : item[Object.keys(statList[0])[1]]
            }));

            setOptionsforState(transformedData1);

        }


        if (!!selectedCoutnry) {
            fetchState();
        }
    }, [selectedCoutnry])


    useEffect(() => {
        const fetchState = async () => {


            let cityList1 = await userServicer.getDropDownlist("GetCity", Number((selectedState as any).OptionID))

            let cityList = cityList1.data;
            const transformedData1 = cityList.map((item: { [x: string]: any; }) => ({
                OptionID: item[Object.keys(cityList[0])[0]],
                OptionName: item[Object.keys(cityList[0])[1]] === undefined ? item[Object.keys(cityList[0])[2]] : item[Object.keys(cityList[0])[1]]
            }));

            setOptionCity(transformedData1);

        }


        if (!!selectedState) {
            fetchState();
        }
    }, [selectedState])

    useEffect(() => {


        const fetchStateById = async (area_ID: Number) => {
            let data = await AreaMasterService.getAreaById(area_ID);
            editRowData = data.data;
            // console.log(editRowData);


            setInputs(editRowData);
            setSelectedCountry((editRowData as any).country_Id);
            setSelectedState((editRowData as any).state_Id);

        }

        setInputs((values: any) => ({ ...values, city_Id: 0, isActive: true }));
        if ((editRowData as any) !== "") {

            fetchStateById((editRowData as any).areaId);
            // setInputs(editRowData);
            setDisable(false);
            setVisibleUpdate(true);
            setUpdCancel(false);
            setCancel(true);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }




    }, [editRowData, use]);

    // useEffect(() => {
    //     closeForm();
    // }, [isSave]);

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        setIssave(true);
        setCancel(true);
        setUpdCancel(true);
        setShowConfirmation(false);

        handleCancel ? setCancel2(false) : setCancel2(true);


    };

    useEffect(() => {

        closeForm(true);


    }, [handleCancel])







    const handelSubmit = (event: any, isSav: any) => {        
        event.preventDefault();


        if (!inputs.areaName) {
            setError((prevErrors) => ({ ...prevErrors, areaName: "Please enter a unique alphanumeric Area name & maximum length of 50 characters" }));
        }
        if (!inputs.areaCode) {
            setError((prevErrors) => ({ ...prevErrors, areaCode: "Please enter a unique alphanumeric Area Code  & maximum length of 10 characters" }));
        }
        if (!inputs.city_Id) {
            setError((prevErrors) => ({ ...prevErrors, city_Id: "Please Select city" }));
        }
        if (!inputs.pinCodeId) {
            setError((prevErrors) => ({ ...prevErrors, pinCodeId: "Please Select Pincode" }));
        }
        if (!selectedCoutnry) {
            setError((prevErrors) => ({ ...prevErrors, countryId: "Please Select Country" }));
        }
        if (!selectedState) {
            setError((prevErrors) => ({ ...prevErrors, stateId: "Please Select State" }));
        }
        if (inputs.areaName !== undefined && inputs.areaCode !== undefined && inputs.pinCodeId !== undefined && inputs.city_Id !== undefined) {
            if (error.areaName === '' && error.areaCode === "" && error.pinCodeId === "" && error.city_Id === "" && error.stateId === "" && error.countryId === "") {
                try {

                    // console.log(inputs);


                    AreaMasterService.SaveorUpdate(inputs).then(user => {

                        if (user.statusCode === 200) {
                            if (user.status === false) {
                                customToast.Warning(user.message);
                                closeForm(isSav);
                                refreshGrid(true);

                            }
                            else {
                                customToast.success(user.message);
                                closeForm(isSav);
                                refreshGrid(true);



                            }
                            //customToast.showToast("Warning: Something went wrong", "warning");

                            // setInputs({});
                            // setDisable(true);
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }
        }

    }

    const closeForm = (isSav: any) => {


        setInputs(initialInputs);
        setError({
            areaName: '',
            areaCode: '',
            pinCodeId: '',
            countryId: '',
            stateId: '',
            city_Id: ''

        })
        setSelectedCountry('');
        setSelectedState('');
        sendDatatoParent("");
        sendViewStatus(false);
        if (isSav) {
            setDisable(true);
            setEditable(true);
            setCancel(true);
        }

        // sendDatatoParent("");
        // sendViewStatus(false);

    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        newInputs = { ...inputs, [name]: value };

        let newError = { ...error };

        if (name === 'areaName') {
            if (!/^[a-zA-Z0-9]{1,50}$/.test(value)) {
                newError.areaName = "Please enter a unique alphanumeric Area Name & maximum length of 50 characters";
            }
            else {
                newError.areaName = '';
            }
        }
        else if (name === 'areaCode') {

            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.areaCode = "Please enter a unique alphanumeric Area Code & maximum length of 10 characters";
            }
            else {
                newError.areaCode = '';
            }
        }

        setInputs(newInputs);
        setError(newError);
        setVisibleUpdate(false);

    };

    const handleFocus = (fieldName: any) => {
        
        if (fieldName == 'areaCode') {
            if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric Area Code  & maximum length of 10 characters" }));
            }
        }
        else if (fieldName == 'areaName') {
            if (!inputs[fieldName]) {
            setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric Area Name  & maximum length of 50 characters" }));
            }
        }
        else if(fieldName == 'pinCodeId') {
            
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter Pincode" }));
            }

        }
        else if(fieldName == 'countryId') {
            
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter Country"}));
            }

        }
        else if(fieldName == "stateId")
        {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter State"}));
            }
        }
        else
        {
            if (!inputs[fieldName]) {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter City"}));
            }
        }

    };

    const handleChangeMUIforCountry = (event: any) => {


        const name = event.target.name;
        const value = event.target.value;
        // console.log(name + ":" + value);
        setSelectedCountry(value);
        setVisibleUpdate(false);
        setError((prevErrors) => ({ ...prevErrors, countryId: '' }));
        //  setInputs((values: any ) => ({ ...values, [name]: value }))

    }

    const clearForm = () => {
        console.log("onabort");


    }


    const handleChangeMUIforState = (event: any) => {

        if(event !== null)
            {
                setSelectedState(event);
                setError((prevErrors) => ({ ...prevErrors, stateId: '' }));

            }
            else
            {
                setOptionCity([{ OptionID: 1, OptionName: "--Select City--" }])
                setError((prevErrors) => ({ ...prevErrors, city_Id: 'Please Select City' }));
            }
       
    }

    let pincode = { OptionName: '' };
    let stateId = { OptionName: '' };
    let cityId = { OptionName: '' };

    const getAutoCompleteValue = (optionModule: any, fieldName: any) => {
        let result = { OptionName: '' };
        if (optionModule !== undefined && optionModule !== null && inputs[fieldName] !== undefined && inputs[fieldName] !== null) {
            let val2 = optionModule.filter((item: any) => item.OptionID === inputs[fieldName]);

            let val4 = val2.length > 0 ? Object.values(val2[0]) : '';

            result.OptionName = val4[1] !== undefined ? String(val4[1]) : '';

        }
        return result;
    }

    pincode = getAutoCompleteValue(optionPincode, 'pinCodeId');
    stateId = getAutoCompleteValue(optionsforState, "state_Id");
    cityId = getAutoCompleteValue(optionCity, "city_Id");

    return (
        <>
            {/* <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;Add Area</span>
                        {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> 
                    </div>
                    <hr className="colorgraph" />
                </div>
            </div> */}

            <div className="area_main">
                <div className="area_content" >
                    <div className="grid-container-area">
                        <div>
                            <TextField
                                disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="areaCode"
                                value={inputs["areaCode"] ? inputs["areaCode"] : ''}
                                label="Area Code"
                                onChange={handleChange}
                                focused={!!inputs.areaCode}
                                onFocus={() => handleFocus("areaCode")}
                                error={!!error.areaCode}
                                helperText={error.areaCode}
                                InputProps={{
                                    readOnly: editable,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.areaCode ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.areaCode ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.areaCode ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="areaName"
                                value={inputs["areaName"] ? inputs["areaName"] : ''}
                                label="Area Name"
                                onChange={handleChange}
                                focused={!!inputs.areaName}
                                onFocus={() => handleFocus('areaName')}
                                error={!!error.areaName}
                                helperText={error.areaName}
                                InputProps={{
                                    readOnly: editable,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.areaName ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{

                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.areaName ? 'red' : undefined
                                    },
                                }}

                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.areaName ? '9px' : '12px',

                                    },
                                }}

                            />
                        </div>
                        <div>



                            <FormControl onFocus={() => handleFocus("pinCodeId")}>
                                <Autocomplete
                                    disabled={disable}
                                    readOnly={editable}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionPincode}
                                    //onChange={handleChangeMUI}
                                    onChange={(event, newValue: any) => {
                                        setInputs((values: any) => ({ ...values, pinCodeId: newValue != null ? newValue.OptionID : '' }));
                                        setVisibleUpdate(false);
                                        setError((prevErrors) => ({ ...prevErrors, pinCodeId: '' }));

                                    }}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}
                                    value={pincode}
                                    
                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.pinCodeId}
                                            {...params}
                                            label="Pincode"
                                            variant="standard"
                                            name="pinCodeId"
                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {error.pinCodeId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.pinCodeId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.pinCodeId}</FormHelperText>}
                            </FormControl>
                        </div>
                        <div>



                            <FormControl focused={!!inputs.country_Id}  variant="standard"  sx={{ width: 200, minWidth: 120, m: -0.3 }}>
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: '#243C5C', borderColor: '#243C5C',
                                }}>Country</InputLabel>
                                <Select
                                    readOnly={editable}
                                    disabled={disable}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    autoComplete="inside"
                                    name="country_Id"
                                    error={!!error.countryId}
                                    //value={inputs.countryId == null ? null : Number(inputs.countryId)}
                                    value={selectedCoutnry}

                                    onChange={handleChangeMUIforCountry}
                                    label="Search c"
                                    onClose={(e) => e.preventDefault()}
                                    

                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {options.map((item: any) => (
                                        <MenuItem key={item.OptionID} value={item.OptionID}>
                                            {item.OptionName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error.countryId ? (
                                    <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.countryId}</FormHelperText>
                                ) : (
                                    <FormHelperText sx={{ color: 'white', fontSize: 9 }}>&nbsp;</FormHelperText>
                                )}
                            </FormControl>

                        </div>
                        <div>
                            <FormControl onFocus={() => !editable && handleFocus("stateId")}>
                                <Autocomplete
                                    readOnly={editable}
                                    disabled={disable}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionsforState}
                                    value={stateId}
                                    //onChange={handleChangeMUIforState}
                                    onChange={(event, newValue: any) => {
                                        handleChangeMUIforState(newValue);
                                        setVisibleUpdate(false);
                                        setInputs((values: any) => ({ ...values, state_Id: newValue != null ? newValue.OptionID : '' }));
                                        setInputs((values: any) => ({ ...values, city_Id:  '' }));
                                        setError((prevErrors) => ({ ...prevErrors, city_Id: "Please Select City" }));

                                    }}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option: any) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}
                                    

                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.stateId}
                                            {...params}
                                            label="State"
                                            variant="standard"
                                            name="state_Id"
                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {error.stateId ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.stateId}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}><>&nbsp;</></FormHelperText>}
                            </FormControl>
                        </div>
                        <div>
                            <FormControl  onFocus={() => !editable && handleFocus("city_Id")}>
                                <Autocomplete
                                    readOnly={editable}
                                    disabled={disable}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={optionCity}
                                    //onChange={handleChangeMUI}
                                    onChange={(event, newValue: any) => {
                                        setVisibleUpdate(false);
                                        setInputs((values: any) => ({ ...values, city_Id: newValue != null ? newValue.OptionID : '' }));
                                        setError((prevErrors) => ({ ...prevErrors, city_Id: '' }));

                                    }}
                                    value={cityId}
                                    getOptionLabel={(option) => option.OptionName}
                                    getOptionKey={(option) => option.OptionID}
                                    sx={{ width: 200, overflowY: 'auto', overflowX: 'hidden', }}
                                    

                                    renderInput={(params) => (
                                        <TextField
                                            error={!!error.city_Id}
                                            {...params}
                                            label="City"
                                            variant="standard"
                                            name="city_Id"

                                            InputLabelProps={{
                                                style: {
                                                    color: '#243C5C',
                                                    fontSize: '12px',
                                                    borderColor: 'red',

                                                },
                                            }}

                                        />

                                    )}
                                />
                                {error.city_Id ? <FormHelperText sx={{ color: 'red', fontSize: 9 }}>{error.city_Id}</FormHelperText> : <FormHelperText sx={{ color: 'white', fontSize: 9 }}>{error.city_Id}</FormHelperText>}
                            </FormControl>
                        </div>



                        <div className="Area_btn">
                            {/* <button disabled={editable ? false : inputs === editRowData ? true : false} className={`btn123 ${disable || editable ? 'inactive' : ''} ${inputs === editRowData ? 'btndisable' : ''}`} onClick={(event) => { (editRowData as any) != '' ?  ( handelSubmit(event, true), setUpdCancel(true)) : handelSubmit(event, false); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp; */}
                            <button
                                disabled={visibleUPdate}
                                //disabled={!editable && inputs === editRowData}
                                className={`btn123 ${disable || editable ? 'inactive' : ''} ${visibleUPdate ? 'btndisable' : ''
                                    }`}
                                onClick={(event) => {
                                    if ((editRowData as any) !== '') {
                                        (error.areaName === '' && error.areaCode === "" && error.pinCodeId === "" && error.city_Id === "" && error.stateId === "" && error.countryId === "") && setUpdCancel(true);
                                        handelSubmit(event, true);
                                    } else {
                                        handelSubmit(event, false);
                                    }
                                }}
                                style={{ paddingRight: 12, height: '30px', marginRight: 12 }}
                            >
                                <Save /> &nbsp;
                                {(editRowData as any) !== '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}
                            </button>

                            {/* <button className={`btn123 ${disable || editable ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); }} type="submit" style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) !== '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp; */}
                            {/* <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { handelSubmit(event); setIssave(true) }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button> */}
                            <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { setIssave(true); handelSubmit(event, true); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>

                            {/* <button className={`btn123 ${updcancel ? 'inactive' : ''}`} onClick={() => { setIssave(true); setCancel(true); setUpdCancel(true); closeForm() }} style={{ paddingRight: 12, height: '30px', alignItems: 'center' }}  ><Close />&nbsp;Close</button> */}

                            <button className={`btn123 ${updcancel ? 'inactive' : ''}  `} onClick={() => { setShowConfirmation(true) }} style={{ paddingRight: 12, height: '30px' }}  ><Close />&nbsp;Close</button>

                            <button className={`btn123 ${disable ? '' : 'inactive'}`} style={{ paddingRight: 12, height: '30px' }} onClick={() => {
                                setDisable(false); setEditable(false);
                                setVisibleUpdate(false);
                                (editRowData as any) = "";
                                setInputs({});
                                setUse(false);
                                setCancel(false);


                            }}><Add />&nbsp;Add</button>&nbsp;
                            &nbsp;
                        </div>
                        <div>


                            {/* <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }} style={{ paddingRight: 12, height: '30px', alignItems: 'center' }}  ><Close />&nbsp;Close</button> */}

                            {/* <button className={`btn123 ${cancel ? 'inactive' : ''}`} onClick={() => { setIssave(true); setCancel(true); closeForm() }} style={{ paddingRight: 12, height: '30px', alignItems: 'center' }}  ><Close />&nbsp;Close</button> */}
                            <button className={`btn123 ${cancel ? 'inactive' : ''}  `} style={{ paddingRight: 12, height: '30px' }} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>


                        </div>

                        {showConfirmation && (
                            <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                                <div className="confirmation-modal">
                                    <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                                    <div className="canel_btns">
                                        <button className="btn123" style={{ marginRight: 10 }} onClick={handleClose}>Cancel</button>
                                        <button className="btn123" style={{ marginLeft: 10 }} onClick={handleConfirmExit}>Yes, Exit</button>
                                    </div>
                                </div>
                            </Popup>

                        )}

                    </div>


                </div>
            </div>
        </>
    );
}

export default AddArea;