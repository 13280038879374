import { commanServicer } from "../Commen_Services/common.services";

export const AreaMasterService = {
    SaveorUpdate,
    getAreaById,


   
}

async function SaveorUpdate(MasterData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(MasterData), true, 'AreaMaster/saveupdate','POST');
}

async function getAreaById(id : any) {
    return await commanServicer.fetchRequest('',true,'AreaMaster/getretrieve?Id='+id,'GET');
}


