import { commanServicer } from "../Commen_Services/common.services";

export const CityMasterService = {
    SaveorUpdate,
    getcitydatabyid,
   
}

async function SaveorUpdate(MasterData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(MasterData), true, 'CityMaster/saveorupdatecitydetails','POST');
}

async function getcitydatabyid(id : any) {
   
    return await commanServicer.fetchRequest('',true,'CityMaster/getcitydatabyid?id='+id,'GET');
}

