import { Add, Cancel, Check, Close, Save } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { designationService } from "../../Commen_Services/Designation.service";
import 'react-toastify/dist/ReactToastify.css';

import customToast from "../../Commen_Component/Toast/CustomToast";
import Popup from "reactjs-popup";

interface propsHead {
    columndata: any[];
    editRowData: any[];
    //editRowData: RowData[];
    sendDatatoParent: any;
    refreshGrid: any;
    sendViewStatus: any;
}

interface RowData {
    [key: string]: any;
}

const AddDesignation: React.FC<propsHead> = ({ columndata, editRowData, sendDatatoParent, refreshGrid, sendViewStatus }) => {

    const [inputs, setInputs] = useState<any>({});
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [disable, setDisable] = useState(true);
    const [editable, setEditable] = useState(true);
    const [error, setError] = useState({
        designationCode: '',
        designationName: '',
        levelId: ''
    });
    const [use, setUse] = useState(true);
    const [isSave, setIssave] = useState(false);
    const [handleCancel, setCancel] = useState(false);






    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const { name, value } = event.target;
        let newError = { ...error };

        let newInputs;
        if (name == 'levelId') {
            
            const value1 = parseInt(value);
            newInputs = { ...inputs, [name]: value1 };


            if (value1 >= 100 || Number.isNaN(value1)) {
                newError.levelId = "Please enter a unique numeric Level ID and Maximum of 2 digits, not allowing '00'";
            }
            else if (value1 <= 0) {
                newError.levelId = "Please enter a unique numeric Level ID and Maximum of 2 digits, not allowing '00'";
            }
            else {
                newError.levelId = '';
            }
        }
        else {
            newInputs = { ...inputs, [name]: value };
        }


        // Validation logic
        if (name === 'designationCode') {
            if (!/^[a-zA-Z0-9]{1,10}$/.test(value)) {
                newError.designationCode = "Please enter a unique alphanumeric Designation Code and Maximum length of 10 characters";
            } else {
                newError.designationCode = '';
            }
        }
        if (name === 'designationName') {
            if (!/^[a-zA-Z0-9\-_]{1,50}$/.test(value)) {
                newError.designationName = "Please enter a unique alphanumeric Designation Name and Maximum length of 50 characters";
            } else {
                newError.designationName = '';
            }
        }
      
        setInputs(newInputs);
        setError(newError);
    };


    const handleFocus = (fieldName: any) => {
        if (!inputs[fieldName]) {
            if (fieldName == "levelId") {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique numeric " + fieldName }));

            }
            if(fieldName == "designationCode")
                {
                    setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric Designation Code"}));
    
                }
            
            if(fieldName == "designationName")
            {
                setError((prevErrors) => ({ ...prevErrors, [fieldName]: "Please enter a unique alphanumeric Designation Name"}));

            }

        }
    };

    // const handelSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const handelSubmit = (event: any, isSav: any) => {
        
        event.preventDefault();

        if (!/^[a-zA-Z0-9]{1,10}$/.test(inputs.designationCode)) {
            //setInputs(values => ({ ...values, designationCode: value }));
            setError({ ...error, designationCode: "Please enter a unique alphanumeric Designation Code and Maximum length of 10 characters" });

        }
        if (!/^[a-zA-Z0-9\-_]{0,50}$/.test(inputs.designationName)) {
            // setInputs(values => ({ ...values, [name]: value }));
            setError({ ...error, designationName: "Please enter a unique alphanumeric Designation Name and Maximum length of 50 characters" });
        }

        if (inputs.levelId && inputs.levelId.length > 2) {
            //setInputs(values => ({ ...values, [name]: value }));
            setError({ ...error, levelId: "Please enter a unique numeric Level ID and Maximum of 2 digits, not allowing '00'" });
        }
        if (!inputs.designationCode) {
            setError((prevErrors) => ({ ...prevErrors, designationCode: "Please enter a unique alphanumeric Designation Code" }));
        }
        if (!inputs.designationName) {
            setError((prevErrors) => ({ ...prevErrors, designationName: "Please enter a unique alphanumeric Designation Name" }));
        }
        if (!inputs.levelId) {
            setError((prevErrors) => ({ ...prevErrors, levelId: "Please enter a unique numeric Level ID" }));
        }

        if (inputs.designationCode != undefined && inputs.designationName != undefined && inputs.levelId != undefined) {
            if (error.designationCode == '' && error.designationName == "" && error.levelId == "") {
                try {

                    // console.log(inputs);

                    designationService.SaveorUpdate(inputs).then(user => {
                        if (user.statusCode == 200) {
                            if (user.status === false) {
                                customToast.Warning(user.message);
                                closeForm(isSav);
                                refreshGrid(true);
                            }
                            else {
                                customToast.success(user.message);
                                //setIssave(true)
                                closeForm(isSav);
                                refreshGrid(true);


                            }
                        }
                    });
                }
                catch (ex) {
                    customToast.error("Something went wrong");
                }
            }



        }


    }

    const closeForm = (isSav: any) => {
        

        setInputs({ id: 0, isActive: true, designationCode: '', designationName: '', levelId: '', isViewData: false });
        setError({
            designationCode: '',
            designationName: '',
            levelId: ''
        });
        sendDatatoParent("");
        sendViewStatus(false);
        // console.log(isSave);


        if (isSav) {
            setDisable(true);
            setEditable(true);
        }



    }

    useEffect(() => {

        setInputs((values: any) => ({ ...values, id: 0, isActive: true }));
        if ((editRowData as any) != "") {

            setInputs(editRowData);
            setDisable(false);
            (editRowData as any).isViewData ? setEditable(true) : setEditable(false);

        }

    }, [editRowData, use])

    // useEffect(() => {
    //     closeForm();
    // }, [isSave]);

    const handleClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmExit = () => {
        setIssave(true);
        setShowConfirmation(false);
        handleCancel ? setCancel(false) : setCancel(true);


    };

    useEffect(() => {

        closeForm(true);


    }, [handleCancel])



    return (<>
        <div style={{ marginLeft: 10 }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <form style={{ display: 'flex', flexDirection: 'row' }} > */}
                <TextField
                    sx={{ marginRight: 1 }}
                    id="standard-basic"
                    variant="standard"
                    name="designationCode"
                    disabled={disable}
                    value={inputs["designationCode"] ? inputs["designationCode"] : '' }
                    label="Designation Code"
                    onChange={handleChange}
                    focused={!!inputs.designationCode}
                    onFocus={() => handleFocus('designationCode')}
                    error={!!error.designationCode}
                    helperText={error.designationCode}
                    InputProps={{
                        readOnly: editable,
                        style: {
                            color: '#243C5C',
                            fontSize: '14px',
                            width: '200px',
                            borderColor: error.designationCode ? 'red' : undefined
                        }
                    }}
                    InputLabelProps={{

                        style: {
                            color: '#243C5C',
                            fontSize: '12px',
                            borderColor: error.designationCode ? 'red' : undefined
                        },
                    }}

                    FormHelperTextProps={{
                        style: {
                            fontSize: error.designationCode ? '9px' : '12px',
                            maxWidth: '210px'

                        },
                    }}

                />

                <TextField

                    sx={{ marginRight: 1 }}
                    id="standard-basic"
                    variant="standard"
                    name="designationName"
                    value={inputs["designationName"] ? inputs["designationName"] : ''}
                    label="Designation Name"
                    disabled={disable}
                    onChange={handleChange}
                    focused={!!inputs.designationName}
                    onFocus={() => handleFocus("designationName")}
                    error={!!error.designationName}
                    helperText={error.designationName}
                    InputProps={{
                        readOnly: editable,
                        style: {
                            color: '#243C5C',
                            fontSize: '14px',
                            width: '200px',
                            borderColor: error.designationName ? 'red' : undefined
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#243C5C',
                            fontSize: '12px',
                            borderColor: error.designationName ? 'red' : undefined
                        },
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: error.designationName ? '9px' : '12px',
                            maxWidth: '210px'

                        },
                    }}
                />
                <TextField
                    sx={{ marginRight: 1 }}
                    id="standard-basic"
                    variant="standard"
                    name="levelId"
                    type="number"
                    disabled={disable}
                    value={inputs["levelId"] ? inputs["levelId"] : ''}
                    label="Level Id"
                    onChange={handleChange}
                    focused={!!inputs.levelId}
                    onFocus={(event: any) => {handleFocus("levelId"); handleChange(event)}}
                    error={!!error.levelId}
                    helperText={error.levelId}
                    

                    InputProps={{
                        inputProps: {
                            step: 3,
                            min: 1,
                             
                        },
                        readOnly: editable,
                        style: {
                            color: '#243C5C',
                            fontSize: '14px',
                            width: '200px',
                            borderColor: error.levelId ? 'red' : undefined
                        },
                        onInput: (event: any) => {
                            
                            const value = event.target.value;
                            if (value == "") {
                                setInputs((values: any) => ({...values,[event.target.name] : event.target.value})
                            )

                            }
                        }
                    }}

                    InputLabelProps={{
                        style: {
                            color: '#243C5C',
                            fontSize: '12px',
                            borderColor: error.levelId ? 'red' : undefined
                        },
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: error.levelId ? '9px' : '12px',
                            maxWidth: '210px'

                        },
                    }}
                />
                <button disabled={editable ? false : inputs === editRowData ? true : false} className={`btn123 ${disable || editable ? 'inactive' : ''} ${inputs === editRowData ? 'btndisable' : ''}`} onClick={(event) => { (editRowData as any) != '' ? handelSubmit(event, true) : handelSubmit(event, false); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;{(editRowData as any) != '' ? <>Update</> : <>Save&nbsp;&&nbsp;New</>}</button>&nbsp;
                
                <button className={`btn123 ${disable || editable || (editRowData as any) != '' ? 'inactive' : ''}`} onClick={(event) => { setIssave(true); handelSubmit(event, true); }} style={{ paddingRight: 12, height: '30px' }}><Save /> &nbsp;Save</button>
                {/* </form> */}

                <button className={`btn123 ${disable ? '' : 'inactive'}`} onClick={() => {
                    setDisable(false);
                    setEditable(false);
                    (editRowData as any) = "";
                    setInputs({});
                    setUse(false);

                }} style={{ paddingRight: 12 }}><Add />&nbsp;Add</button>&nbsp;
                &nbsp;
                {/* <button className={`btn123 ${disable ? 'inactive' : ''}`} onClick={() => { setIssave(true); closeForm() }}  ><Close />&nbsp;Close</button> */}
                <button className={`btn123 ${disable ? 'inactive' : ''}  `} onClick={() => { setShowConfirmation(true) }}  ><Close />&nbsp;Close</button>

                {showConfirmation && (
                    <Popup contentStyle={{ height: 105, width: 275, padding: 0 }} position='top center' open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                        <div className="confirmation-modal">
                            <p>Are you sure you want to exit? <br />(Any unsaved changes will be lost.)</p>
                            <div className="canel_btns">
                                <button className="btn123" style={{marginRight: 10}} onClick={handleClose}>Cancel</button>
                                <button className="btn123" style={{marginLeft:10}} onClick={handleConfirmExit}>Yes, Exit</button>
                            </div>
                        </div>
                    </Popup>
                )}

            </div>


        </div>
    </>);



}

export default AddDesignation;


