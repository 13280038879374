import React from "react";
import empty_state from "../assets/Images/empty_state.png"

import load from "../assets/Images/404-animation.gif"


const BadRequest = () => {


    return (
        <>
           {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={empty_state} height="300px" alt="Empty State" />
           </div> */}
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={load} height="300px" alt="Empty State" />
            </div>
        </>
    )
}

export default BadRequest