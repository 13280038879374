import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useParams, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login/Login';
import { ToastContainer } from 'react-toastify';
import MasterRouting2 from './App/Masters/MasterRouting2';
import CposRoutingmodule from './App/Module/CposRoutingmodule';
import Popup from 'reactjs-popup';
import Logoutpopup from './pages/Login/Logoutpopup';
import PageNotFound from './pages/PageNotFound';
import BadRequest from './pages/BadRequest';
import { LinearProgress } from '@mui/material';
import { userServicer } from './App/Commen_Services/user.services';


function App() {

  const [token, setToken] = useState(localStorage.getItem('user'));
  const [showlogoutPopup, setlogoutShowPopup] = useState(false);


  // useEffect(() => {

  //   const storedToken = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : '');
  //   if (storedToken) {
  //     setToken(storedToken.access_token);
  //   }
  // }, []);

  const handleLogin = (newToken: any) => {

    // console.log(newToken);

    localStorage.setItem('user', JSON.stringify(newToken.data));
    setToken(newToken.data.access_token);
  };

  const handleLogout = async () => {
    try {
      const result = await userServicer.logOut();
      localStorage.removeItem('user');
      setToken(null);
      window.location.href = "/";    
    }
    catch {
      console.log("error While fetchiung Data");

    }
    // debugger
    // if (result) {
    //   localStorage.removeItem('user');
    //   setToken(null);
    // }
  };




  return (

    <>

      <Router>
        <Routes>         
          <Route path="/" element={token ? <CposRoutingmodule onLogout={handleLogout} /> : <Login onLogin={handleLogin} />} >   
            <Route path='/Master/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >
              <Route path=":pageId" element={<MasterRouting2 />} />
              <Route path=':action' element={<MasterRouting2 />} />
              <Route path=':action/:pageId' element={<MasterRouting2 />} />
            </Route>
            <Route path="/Report" element={<CposRoutingmodule onLogout={handleLogout} />} >
              <Route path=":componentName/:pageId" element={<CposRoutingmodule onLogout={handleLogout} />} />
            </Route>
            <Route path='/User/:componentName' element={<CposRoutingmodule onLogout={handleLogout} />} >

            </Route>

          </Route>
          <Route path='/Pagenotfound' element={<BadRequest />} />

        </Routes>
        <ToastContainer position="top-center" />
        {/* <Popup contentStyle={{ height: 250, overflowy: "auto", width: 450, padding: 0 }} open={showlogoutPopup} onClose={() => setlogoutShowPopup(false)} children={undefined}>
        <Logoutpopup />
      </Popup> */}

      </Router>
    </>
  );
}

export default App;
