import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { userServicer } from "../../../Commen_Services/user.services";
import { EmployeeBankDetails } from "./EmployeeModel";



interface propsHead {
    sendDataToParent: any;
    formData: any;
    sendErrorToParent: any;
    errorData: any;
    isViewData: boolean;
}
const BankDetails: React.FC<propsHead> = ({sendDataToParent, formData, sendErrorToParent, errorData, isViewData}) => {

    const [inputs, setInputs] = useState<EmployeeBankDetails>({
        nameOnBankAccount: '',
        bankName: '',
        back_Account_No: '',
        ifsC_Code: ''
    });
    const [error, setError ] = useState<any>({});
    // const [error, setError] = useState({
    //     nameOnBankAccount: '',
    //     bankName: '',
    //     back_Account_No: '',
    //     ifsC_Code: '',
    // });





 
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        let newInputs;
        newInputs = { ...inputs, [name]: value };

        let newError = { ...error };
        if (name === 'nameOnBankAccount') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.nameOnBankAccount = "Please enter a valid Name";
            }
            else {
                newError.nameOnBankAccount = '';
            }
        }
        if (name === 'bankName') {

            if (value !== "" && !/^[a-zA-Z0-9\s]{2,100}$/.test(value)) {
                newError.bankName = "Please enter a valid bank ";
            }
            else {
                newError.bankName = '';
            }
        }
        // if (name === 'personal_Email') {

        //     if (!/^[a-zA-Z]{1,10}$/.test(value)) {
        //         newError.personal_Email = "Code should be Alphabetic & max 50 characters";
        //     }
        //     else {
        //         newError.personal_Email = '';
        //     }
        // }


        setInputs(newInputs);
        setError(newError);

    };









    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };
        let isDifferent = areObjectsEqual(inputs,formData);
        if(!isDifferent)
        {
            sendDataToParent(inputs);
        }
        // sendDataToParent(inputs)

    },[inputs])
    useEffect(() => {
        sendErrorToParent(error);
    },[error])

    useEffect(() => {
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };

        let isDifferent = areObjectsEqual(error,errorData);
        
         
        if(!isDifferent)
        {
            // setInputsforChild(inputs);
                setError(errorData);  
        } 

    },[errorData]);

    useEffect(() => {
        
        const areObjectsEqual = (objA: any, objB: any): boolean => {
            return JSON.stringify(objA) === JSON.stringify(objB);
          };

        let isDifferent = areObjectsEqual(inputs,formData);

        const checkDiff = () => {
            return (inputs.back_Account_No != formData.back_Account_No   ||
                inputs.bankName != formData.bankName  ||
                inputs.ifsC_Code != formData.ifsC_Code  ||
                inputs.nameOnBankAccount != formData.nameOnBankAccount 
                );
        }

        if(checkDiff())  
        //if(!isDifferent)
        {
            // setInputsforChild(inputs);
                setInputs(formData);     
        } 
    },[formData])
    return (
        <>
            <div className="personal_Info">
                <div>Bank Details</div>
                {/* <hr /> */}
                <div>
                    <div className="Profile_dtl">
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="nameOnBankAccount"
                                value={inputs.nameOnBankAccount}
                                label="As per name Bank"
                                focused={!!inputs.nameOnBankAccount}
                                onChange={handleChange}
                                error={!!error.nameOnBankAccount}
                                helperText={error.nameOnBankAccount}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.nameOnBankAccount ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.nameOnBankAccount ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.nameOnBankAccount ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="bankName"
                                value={inputs.bankName}
                                label="Bank Name"
                                focused={!!inputs.bankName}
                                onChange={handleChange}
                                error={!!error.bankName}
                                helperText={error.bankName}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.bankName ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.bankName ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.bankName ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="back_Account_No"
                                type="number"
                                value={inputs.back_Account_No}
                                label="Bank Account Number"
                                focused={!!inputs.back_Account_No}
                                onChange={handleChange}
                                error={!!error.back_Account_No}
                                helperText={error.back_Account_No}
                                onKeyDown={(evt) => ["e", "E", "+", "-",".","/"].includes(evt.key) && evt.preventDefault()}
                                sx={{
                                    "input::-webkit-inner-spin-button" : {
                                        "-webkit-appearance": "none",
                                        "margin": "0"
                                    }
                                }}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.back_Account_No ? 'red' : undefined,
                                       
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.back_Account_No ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.back_Account_No ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                        <div className="profile_grid_c">
                            <TextField
                                // disabled={disable}
                                id="standard-basic"
                                variant="standard"
                                name="ifsC_Code"
                                value={inputs.ifsC_Code}
                                label="IFSC Code"
                                focused={!!inputs.ifsC_Code}
                                onChange={handleChange}
                                error={!!error.ifsC_Code}
                                helperText={error.ifsC_Code}
                                InputProps={{
                                    readOnly: isViewData,
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.ifsC_Code ? 'red' : undefined
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.ifsC_Code ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.ifsC_Code ? '9px' : '12px',

                                    },
                                }}
                            />
                        </div>
                       
                    </div>
                </div>
             
                
               
                

           
                


            </div>
        </>
    )
}

export default BankDetails;